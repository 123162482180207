const routers = [
  {
    path: '/',
    meta: {
      title: '登录-智能硬件管理系统',
    },
    component: (resolve) => require(['./views/index.vue'], resolve),
  },
  {
    path: '/check-health',
    meta: {
      title: '健康检查-智能硬件管理系统',
    },
    component: (resolve) => require(['./views/check-health.vue'], resolve),
  },
  {
    //落地页内嵌路由
    path: '/manage/device',
    meta: {
      title: '设备管理',
    },
    component: (resolve) => require(['./views/devices/list.vue'], resolve),
  },
  {
    //落地页内嵌路由
    path: '/trail/back',
    meta: {
      title: '轨迹回放',
    },
    component: (resolve) =>
      require(['./views/devices/trail-back.vue'], resolve),
  },
  {
    path: '/main',
    meta: {
      title: '主页面',
    },
    component: (resolve) => require(['./views/main.vue'], resolve),
    children: [
      {
        path: '/index',
        name: '首页',
        component: (resolve) => require(['./views/index/index.vue'], resolve),
      },

      {
        path: '/manage/device-query',
        name: '设备管理',
        component: (resolve) => require(['./views/devices/list.vue'], resolve),
      },
      {
        path: '/devices/trail_back',
        name: '轨迹回放',
        component: (resolve) =>
          require(['./views/devices/trail-back.vue'], resolve),
      },
      {
        path: '/monitor',
        name: '监控中心',
        component: (resolve) =>
          require(['./views/monitor/center.vue'], resolve),
      },
      {
        path: '/config',
        name: '企业配置',
        component: (resolve) => require(['./views/config/index.vue'], resolve),
      },
      // {
      //     path: "/set/base-set",
      //     name: "基本设置",
      //     component: (resolve) =>
      //         require(["./views/set/base-set.vue"], resolve),
      // },
      {
        path: '/user/list',
        name: '客户列表',
        component: (resolve) => require(['./views/user/list.vue'], resolve),
      },
      {
        path: '/user/user_group',
        name: '权限管理',
        component: (resolve) => require(['./views/user/user_group'], resolve),
      },
      {
        path: '/monitor/list',
        name: '自动建单监控',
        component: (resolve) => require(['./views/monitor/list.vue'], resolve),
      },
      {
        path: '/map/transport_bill_track',
        name: '运单轨迹',
        component: (resolve) =>
          require(['./views/map/transportBillTrack.vue'], resolve),
      },
      {
        path: '/map/trouble_shoot',
        name: '问题排查',
        component: (resolve) =>
          require(['./views/map/troubleShoot.vue'], resolve),
      },
    ],
  },
]
export default routers
