<template>
  <router-view></router-view>
</template>
<script>
import axios from 'axios'
// import { ajax } from "./libs/util";
export default {
  data() {
    return {}
  },
  mounted() {
    this.interceptors()
    // ajax.post("/user/login",{}, (res) => {
    //   if (res.status === 200) {
    //     //console.log('login:',res);
    //     sessionStorage.authString = res.data.authString; //权限
    //   }
    // });
  },
  beforeDestroy() {},
  methods: {
    interceptors() {
      // 添加一个axios拦截器，拦截status为401的情况，跳转到登录界面
      let that = this
      axios.interceptors.response.use(
        function(response) {
          if (response.data.status == 401) {
            that.$router.push({ path: '/' })
            return null // 未登录跳转到主页，不显示错误信息
          } else {
            return response
          }
        },
        function(error) {
          return Promise.reject(error)
        }
      )
    },
  },
}
</script>
