import Vue from 'vue'
import ViewUI from 'view-design'
import VueRouter from 'vue-router'
import Routers from './router.js'

import { setTitle } from './libs/util'
import App from './app.vue'
import 'view-design/dist/styles/iview.css'
import './css/base.less'
import { ajax } from './libs/util'

Vue.use(VueRouter)
Vue.use(ViewUI, {
  size: 'large',
})
Vue.use({
  install(vue) {
    vue.prototype.$ajax = ajax
  },
})

//全局配置
window.CONFIG = {
  pageSize: 20, //列表页每页显示多少条
}

// 路由配置
const RouterConfig = {
  mode: 'history',
  routes: Routers,
}
const router = new VueRouter(RouterConfig)

router.beforeEach((to, from, next) => {
  ViewUI.LoadingBar.start()
  setTitle(to.meta.title)
  next()
})

router.afterEach(() => {
  ViewUI.LoadingBar.finish()
  window.scrollTo(0, 0)
})

new Vue({
  el: '#app',
  router: router,
  render: (h) => h(App),
})
